import Image from '../../Image/Image'
import MarkdownText from '../../MarkdownText/MarkdownText'
import './style.scss'

interface IIconTextProps {
  img: string
  img_alt?: string
  title: string
  description: string
}

const IconText = ({
  img,
  img_alt = '',
  title,
  description,
}: IIconTextProps) => {
  return (
    <div className="IconText">
      <Image
        src={img.startsWith('https://') ? img : `/images/${img}`}
        alt={img_alt}
        data-testid="icon-text-img"
      />
      <h3>{title}</h3>
      <MarkdownText text={description} />
    </div>
  )
}

export default IconText
