import IconText from '../Hubs/IconText/IconText'
import LinkComponent from '../LinkComponent/LinkComponent'
import './IconRowSection.scss'

interface IIconRowSectionProps {
  title: string
  subtitle: string
  icons: { image: string; title: string }[]
  cta_link?: { text: string; url: string }
}

const IconRowSection = (props: IIconRowSectionProps) => {
  const { title, subtitle, icons, cta_link } = props

  const isCTALink =
    cta_link && cta_link.text.length > 0 && cta_link.url.length > 0

  return (
    <div className={`IconRowSection ${!isCTALink ? 'pb-[80px]' : ''}`}>
      <h2 className="IconRowSection__header">{title}</h2>
      <h3 className="IconRowSection__subheader">{subtitle}</h3>
      <ul className="IconRowSection__icon-container">
        {icons.map((icon) => (
          <li key={icon.image}>
            <IconText img={icon.image} title={icon.title} description="" />
          </li>
        ))}
      </ul>
      {isCTALink && (
        <div
          className="IconRowSection__cta"
          data-testid="icon-row-section-cta-link"
        >
          <LinkComponent href={cta_link?.url}>
            {cta_link?.text}
            <i className="bx bx-chevron-right" />
          </LinkComponent>
        </div>
      )}
    </div>
  )
}

export default IconRowSection
